import PropTypes from "prop-types";
import Head from "next/head";
import { withTranslation } from "../../i18n";

const MetaContent = ({
  meta: {
    title,
    description,
    image = "/static/images/main-cover_cr.jpg",
    concatName = true,
  },
  t,
}) => {
  const metaDescription = description || t("DESCRIPTION_SEO");
  const metaImage = image || "/static/images/main-cover_cr.jpg";
  const UMNLogo = '/static/images/umnLogo.png'

  return (
    <Head>
      {title && (
        <title>
          {title}
          {concatName ? ` | Ural Music Night` : ""}
        </title>
      )}
      <meta property="og:type" content="website" />
      <meta name="apple-itunes-app" content="app-id=1377024736" />
      <meta name="google-play-app" content="app-id=ru.doubletapp.umn" />

      {title && <meta property="og:title" content={title} />}
      <meta name="description" content={metaDescription} />
      <meta name="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />

      <link rel="android-touch-icon" href={UMNLogo}/>
    </Head>
  );
};

MetaContent.propTypes = {
  meta: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
};
MetaContent.defaultProps = {};

export default withTranslation(["about"])(MetaContent);
